import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  appLocalesLanguageList = [
    {
      key: 'en-US',
      Label: '(en-US) English (United States)',
    },
    {
      key: 'de-DE',
      Label: '(de-DE) Deutsch (Deutschland)',
    },
    {
      key: 'es-ES',
      Label: '(es-ES) español (España)',
    },
    {
      key: 'it-IT',
      Label: '(it-IT) italiano (Italia)',
    },
    {
      key: 'nb-NO',
      Label: '(nb-NO) Norsk bokmål (Norge)',
    },
    {
      key: 'nl-NL',
      Label: '(nl-NL) Nederlands (Nederland)',
    },
    {
      key: 'sv-SV',
      Label: '(sv-SV) Swedish (Swedish)',
    },
    {
      key: 'da-DA',
      Label: '(da-DA) Danish (Danish)',
    },
    {
      key: 'fr-FR',
      Label: '(fr-FR) français (France)',
    },
    {
      key: 'el-GR',
      Label: '(el-GR) Ελληνικά (Ελλάδα)',
    },
    {
      key: 'cs-CZ',
      Label: '(cs-CZ) čeština (Česká republika)',
    },
    {
      key: 'hu-HU',
      Label: '(hu-HU) magyar (Magyarország)',
    },
    {
      key: 'pl-PL',
      Label: '(pl-PL) polski (Polska)',
    },
    {
      key: 'pt-PT',
      Label: '(pt-PT) português (Portugal)',
    },
    // {
    //   key: 'ru-RU',
    //   Label: '(ru-RU) русский (Россия)',
    // },
    {
      key: 'sl-SL',
      Label: '(sl-SL) Slovenščina',
    },
    {
      key: 'sk-SK',
      Label: '(sk-SK) Slovenčina',
    },
    {
      key: 'tr-TR',
      Label: '(tr-TR) Türkçe (Türkiye)',
    },
    {
      key: 'pt-BR',
      Label: '(pt-BR) português (Brasil)',
    },
  ];

  appRegionsDescription = [
    {
      key: 'US/Pacific-PST',
      Label: 'United States (PST)',
      zone: 'PST',
    },
    {
      key: 'Europe/Berlin-CET',
      Label: 'Germany (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Madrid-CET',
      Label: ' Spain (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Rome-CET',
      Label: 'Italy (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Oslo-CET',
      Label: 'Norway (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Amsterdam-CET',
      Label: 'Netherland (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Stockholm-CET',
      Label: 'Sweden (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Copenhagen-CET',
      Label: 'Denmark (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Paris-CET',
      Label: 'France (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Athens-EET',
      Label: 'Greece (EET)',
      zone: 'EET',
    },
    {
      key: 'Europe/Prague-CET',
      Label: 'Czech Republic (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Budapest-CET',
      Label: 'Hungary (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Warsaw-CET',
      Label: 'Poland (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Lisbon-GMT',
      Label: 'Portugal (GMT)',
      zone: 'GMT',
    },
    {
      key: 'Europe/Ljubljana-CET',
      Label: 'Slovenia (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Bratislava-CET',
      Label: 'Slovakia (CET)',
      zone: 'CET',
    },
    {
      key: 'Turkey-TRT',
      Label: 'Turkey (TRT)',
      zone: 'TRT',
    },
    {
      key: 'Brazil/East-BRT',
      Label: 'Brazil (BRT)',
      zone: 'BRT',
    },
    {
      key: 'Europe/London-GMT',
      Label: 'Britain (GMT)',
      zone: 'GMT',
    },
    {
      key: 'Europe/Moscow-MST',
      Label: 'Россия (MST)',
      zone: 'MST',
    },
  ];

  getLanguage(): string {
    return (sessionStorage.getItem('lang') as string)
      ? (sessionStorage.getItem('lang') as string)
      : 'en-US';
  }
  setLanguage(language: string) {
    sessionStorage.setItem('lang', language);
  }

  getRegion(): string {
    return sessionStorage.getItem('region' as string)
      ? (sessionStorage.getItem('region') as string)
      : 'US/Pacific-PST';
  }
  setRegion(Region: string) {
    sessionStorage.setItem('region', Region);
  }
}
